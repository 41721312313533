import React from 'react';
import ReactHlsPlayer from 'react-hls-player';
import './App.css';

function App() {

  const playerRef = React.useRef<HTMLVideoElement>(null);
  // useEffect(() => {
  // document.addEventListener('keydown', onKeyDown);
  // return () => document.removeEventListener("keydown", onKeyDown)
  //}, [])

  return (
    <div className='video'>
      <ReactHlsPlayer
        playerRef={playerRef}
        src="https://cdn.jmvstream.com/w/LVW-10893/LVW10893_gYHYD18Dqy/playlist.m3u8"
        autoPlay={true}
        controls={false}
        width="100%"
        height="100%"
        hlsConfig={{
          lowLatencyMode: true,
        }}
        onError={(e) => {
          console.log(e)
        }}
      />
    </div>
  );
}

export default App;
